// import React from "react";
// import { StaticQuery, graphql } from 'gatsby';
// import NewsQuilt from "src/components/news-quilt.js";

// /**
//  *  News quilt for Education category
//  */
// class NewsQuiltEducation extends React.Component {
//   render() {
//     return (
//       <StaticQuery
//         query={graphql`
//             query {
//               allWordpressPost(
//                 filter: {
//                   categories: {
//                     elemMatch: {
//                       name: {
//                         eq: "Education"
//                       }
//                     }
//                   }
//                 }
//                 limit: 3
//                 sort: { fields: [date], order: DESC }
//               ) {
//                 edges {
//                   node {
//                     ...NewsQuilt
//                   }
//                 }
//               }
//             }
//           `}
//         render={data => (
//           <NewsQuilt
//             newsQuiltData={data.allWordpressPost}
//             quiltPanelsCount={this.props.quiltPanelsCount}
//             quiltStyle={this.props.quiltStyle}
//           />
//         )}
//       />
//     );
//   }
// }

// export default NewsQuiltEducation;










import React from "react";
import NewsQuilt from "src/components/news-quilt.js";

/**
 *  News quilt for Education category
 */
class NewsQuiltEducation extends React.Component {
  state = {
    newsQuiltInfo: []
  }

  componentDidMount() {
    fetch("https://thehelm.polb.com/wp-json/wp/v2/posts?categories=364&per_page=3")
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ newsQuiltInfo: json });
        }
      })
  }

  render() {
    // console.log(this.props);
    return (
      <React.Fragment>
        {
          this.state.newsQuiltInfo.length > 0 &&
          <NewsQuilt
            newsQuiltData={this.state.newsQuiltInfo}
            quiltPanelsCount={this.props.quiltPanelsCount}
            quiltStyle={this.props.quiltStyle}
          />
        }
      </React.Fragment>
    );
  }
}

export default NewsQuiltEducation;