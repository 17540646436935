import React from "react";
import { graphql } from 'gatsby';

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";
import EducationCards from "src/components/education-cards";
import EducationInfo from "src/components/education-info";
import NewsQuiltEducation from "src/components/news-quilt-education.js";

/**
 * Education landing page
 * (special intermediary between Education main page and Education Resources subpage)
 */
class Education extends React.Component {
  state = {
    pageInfo: []
  }

  componentDidMount() {
    fetch("https://thehelm.polb.com/wp-json/wp/v2/pages?slug=education")
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ pageInfo: json });
        } else {
          console.log("Data did not load");
        }
      })
  }

  render() {
    const pageData = this.state.pageInfo[0];

    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero>
              <SEO title={pageData.title.rendered} description="" />
              {(pageData.parent === 0 && pageData.acf) && (
                <Hero type="short" headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} mobileHeroBackgroundUrl={pageData.acf.mobile_background} subheading={pageData.acf.subheading} />
              )}

              <Breadcrumb categories={pageData.categories} pageTitle={pageData.title.rendered} />
              <EducationCards />
              <EducationInfo />
              <NewsQuiltEducation quiltStyle="pattern-01" />
            </Layout>
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default Education;

// export const pageQuery = graphql`
//   query($tagName: String = "dev_only_id_education") {
//     wordpressPage(tagNames: { in: [$tagName] }) {
//       id
//       slug
//       title
//       wordpress_parent
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//       categories {
//         name
//         slug
//       }
//     }
//   }
// `;
